<template>
  <main v-if="campaign">
    <div>
      <div class="flex items-center mb-16 w-full">
        <Avatar v-if="getBrandImg" v-lazy="getBrandImg" size="large" class="mr-4" />

        <div>
          <Title size="large" :title="campaign.title" />

          <span class="title__label">Ad campaign</span>
        </div>

        <Button kind="secondary" class="ml-auto" label="Settings" @clicked="editCampaign" />
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
        <KPI
          label="Streamers"
          :value="campaign && campaign.handles && campaign.handles.length ? campaign.handles.length : 0"
          title="Total amount of streamers part of campaign"
        />

        <KPI
          label="Average viewers"
          :value="getTotalAverageViews"
          title="Average amount of views for each ad display"
        />

        <KPI label="Ad views" :value="getTotalViews" title="Total ad views" />

        <KPI label="Times displayed" :value="getTotalTimesDisplayed" title="Amount of times ad has been displayed" />

        <KPI label="Link clicks" :value="getTotalLinkClick" title="Amount of times campaign link has been clicked" />

        <KPI label="Link CTR" :value="getTotalLinkCtr" unit="%" title="Click-through rate ((clicks / views) * 100)" />

        <KPI label="Unique clicks" :value="uniqueClicks" title="Amount of clicks from unique ips" />

        <KPI
          label="Unique CTR"
          :value="getUniqueCtr"
          unit="%"
          title="Unique click-through rate ((clicks / views) * 100)"
        />
      </div>

      <div class="flex flex-wrap mb-16 w-full max-w-screen">
        <div v-if="viewsByDay && viewsByDay.series && viewsByDay.labels" class="mb-6 col--4">
          <Title size="medium" title="Views" />

          <AreaChart :series="viewsByDay.series" :labels="viewsByDay.labels" />
        </div>

        <div v-if="clicksByDay && clicksByDay.series && clicksByDay.labels" class="mb-6 col--4">
          <Title size="medium" title="Clicks" />

          <AreaChart :series="clicksByDay.series" :labels="clicksByDay.labels" />
        </div>

        <div v-if="ctrByDay && ctrByDay.series && ctrByDay.labels" class="mb-6 col--4">
          <Title size="medium" title="CTR" />

          <AreaChart :series="ctrByDay.series" :labels="ctrByDay.labels" />
        </div>

        <div v-if="gameCategories && gameCategories.length" class="mb-6 col--4">
          <PieChart title="Top categories" :data-set="gameCategories" />
        </div>
      </div>

      <div class="flex items-center mb-8 w-full">
        <Title size="medium" title="Streamer stats" />

        <Button
          title="A streamer count as active if they have delivered any amount of views"
          class="ml-auto"
          kind="link"
          :label="showInactiveStreamers ? 'Hide inactive streamers' : 'Show inactive streamers'"
          @clicked="showInactiveStreamers = !showInactiveStreamers"
        />

        <Button class="ml-10" kind="secondary" label="Export CSV" @clicked="exportStreamerDataCSV" />
      </div>

      <div class="flex mb-16 w-full">
        <List
          class="list--campaigns"
          :sort="[
            {
              label: 'Handle',
              prop: 'date',
              active: true,
              emit: 'sortByHandle',
            },
            {
              label: 'Views',
              prop: 'streamerAmount',
              align: 'center',
              emit: 'sortByViews',
            },
            {
              label: 'Clicks',
              prop: 'date',
              align: 'center',
              emit: 'sortByClicks',
            },
            {
              label: 'CTR',
              prop: 'date',
              align: 'center',
              emit: 'sortByCTR',
            },
            {
              label: 'Times displayed',
              prop: 'date',
              align: 'center',
              emit: 'sortByTimesDisplayed',
            },
            {
              label: '',
              prop: '',
            },
          ]"
          @sortByHandle="handleSort('handle')"
          @sortByViews="handleSort('views')"
          @sortByClicks="handleSort('clicks')"
          @sortByCTR="handleSort('ctr')"
          @sortByTimesDisplayed="handleSort('timesDisplayed')"
        >
          <li v-for="(handle, i) of computedCampaign.handles" :key="i" class="list__item">
            <div class="list__item-img">
              <img
                v-lazy="`https://storage.googleapis.com/cavea-avatar-service/twitch/${handle.handle}/avatar.png`"
                :alt="`${handle.handle} profile image`"
                @error="profileImageFallback"
              />
            </div>

            <div class="list__item-content">
              <div class="list__item-col" data-col="1">
                <Button
                  class="flex mb-2 text-base font-medium button pointer"
                  :url="`/profile/${handle.handle}`"
                  kind="link"
                  :new-window="false"
                >
                  <div
                    class="mr-2"
                    :style="`width:10px; height: 10px; border-radius: 50%; background: ${
                      handleStreamStatus(handle.handle) ? '#5ece8a' : '#d64646'
                    }; `"
                  />

                  {{ handle.handle }}

                  <Tag
                    v-if="getHandleOrgTitle(handle.orgId)"
                    :label="getHandleOrgTitle(handle.orgId)"
                    :random-color="true"
                    class="ml-2"
                  />

                  <Tag v-if="handle.active === false" label="DISABLED" class="ml-2 bg-signal-red" />
                </Button>

                <div class="flex items-center w-full">
                  <a
                    :href="`https://twitch.tv/${handle.handle}`"
                    target="_blank"
                    rel="noreferrer noopener "
                    class="pointer"
                  >
                    <span class="mr-4 text-xs capitalize ellipsis color--blue-lighter">
                      {{ handle.platform }}
                    </span>
                  </a>

                  <p
                    class="p-1 text-xs rounded border ellipsis color--blue-lighter"
                    :style="`border: 2px solid ${
                      handle.contentCreatorId._id && overlayStatuses.get(handle.contentCreatorId._id) ? 'green' : 'red'
                    }`"
                  >
                    Last overlay visit:
                    {{
                      handle.contentCreatorId._id && overlayStatuses.get(handle.contentCreatorId._id)
                        ? new Date(overlayStatuses.get(handle.contentCreatorId._id)).toLocaleDateString()
                        : "unknown"
                    }}
                  </p>
                </div>
              </div>

              <div class="items-center list__item-col" data-col="2">
                <p v-if="handle.handle" class="mb-2 text-sm font-medium button ellipsis">
                  {{ getStreamerViews(handle.handle) || 0 }}
                </p>
              </div>

              <div class="items-center list__item-col" data-col="3">
                <p v-if="handle.handle" class="mb-2 text-sm font-medium button ellipsis">
                  {{ getStreamerClicks(handle.handle) }}
                </p>
              </div>

              <div class="items-center list__item-col" data-col="4">
                <p v-if="handle.handle" class="mb-2 text-sm font-medium button ellipsis">
                  {{ getStreamerCtr(handle.handle) }}%
                </p>
              </div>

              <div class="items-center list__item-col" data-col="5">
                <p v-if="handle.handle" class="mb-2 text-sm font-medium button ellipsis">
                  {{ getStreamerDisplayTimes(handle.handle) }}
                </p>
              </div>

              <div class="list__item-col" data-col="0">
                <Dropdown
                  :position="['right']"
                  :items="[
                    {
                      index: i,
                      id: `${handle.contentCreatorId._id}`,
                      label: 'Copy stream code',
                    },
                    {
                      index: i,
                      id: `${handle.contentCreatorId._id}`,
                      label: 'Copy redirect url',
                    },
                    {
                      index: i,
                      id: `${handle.contentCreatorId._id}`,
                      label: handle.active ? 'Disable handle' : 'Enable handle',
                    },
                    {
                      index: i,
                      id: `${handle.contentCreatorId._id}`,
                      label: 'Set organization',
                    },
                    {
                      index: i,
                      id: `${handle.contentCreatorId._id}`,
                      label: 'Set CPM',
                    },
                  ]"
                  :is-show-more="true"
                  @change="dropdownClick"
                />
              </div>
            </div>
          </li>
        </List>
      </div>

      <div v-if="checkIfAdminOrg" id="join-requests" class="flex items-center mb-8 w-full">
        <Title title="Requests to join campaign" size="medium" />
      </div>

      <div v-if="checkIfAdminOrg" class="flex mb-16 w-full">
        <List
          class="list--campaigns"
          :sort="[
            {
              label: 'Handle',
              prop: 'date',
              active: true,
            },
            {
              label: 'Broadcasting language',
              prop: 'streamerAmount',
              align: 'center',
            },
            {
              label: 'Country',
              prop: 'date',
              align: 'center',
            },
            {
              label: 'Streamer status',
              prop: 'date',
              align: 'center',
            },
            {
              label: 'Campaign status',
              prop: 'date',
              align: 'center',
            },
            {
              label: '',
              prop: '',
            },
          ]"
        >
          <li v-for="(request, requestIndex) of joinRequests" :key="requestIndex" class="list__item">
            <div class="list__item-img">
              <img
                v-lazy="
                  `https://storage.googleapis.com/cavea-avatar-service/twitch/${request.contentCreatorId.username}/avatar.png`
                "
                :alt="`${request.contentCreatorId.username} profile image`"
                @error="profileImageFallback"
              />
            </div>

            <div class="list__item-content">
              <div class="list__item-col" data-col="1">
                <Button
                  class="flex mb-2 text-sm font-medium button"
                  :url="`/profile/${request.contentCreatorId.username}`"
                  :new-window="false"
                  kind="link"
                  :capitalize="true"
                  :label="request.contentCreatorId.username"
                />

                <div class="flex items-center w-full">
                  <a
                    :href="`https://twitch.tv/${request.contentCreatorId.username}`"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <span class="mr-4 text-xs capitalize ellipsis color--blue-lighter"> Twitch </span>
                  </a>
                </div>
              </div>

              <div class="items-center list__item-col" data-col="2">
                <Button
                  class="mb-2 text-sm font-medium capitalize button ellipsis"
                  :label="
                    request &&
                    request.contentCreatorId &&
                    request.contentCreatorId.info &&
                    request.contentCreatorId.info.broadcastingLanguage
                      ? request.contentCreatorId.info.broadcastingLanguage
                      : 'N/A'
                  "
                  kind="link"
                  :capitalize="true"
                />
              </div>

              <div class="items-center list__item-col" data-col="3">
                <Button
                  kind="link"
                  class="mb-2 text-sm font-medium button ellipsis"
                  :capitalize="true"
                  :label="
                    request &&
                    request.contentCreatorId &&
                    request.contentCreatorId.info &&
                    request.contentCreatorId.info.country
                      ? request.contentCreatorId.info.country
                      : 'N/A'
                  "
                />
              </div>

              <div class="items-center list__item-col" data-col="4">
                <Button
                  kind="link"
                  class="mb-2 text-sm font-medium button ellipsis"
                  :capitalize="true"
                  :label="request.contentCreatorAccepted ? 'Accepted' : 'Pending'"
                />
              </div>

              <div class="items-center list__item-col" data-col="5">
                <Button
                  kind="link"
                  class="mb-2 text-sm font-medium button ellipsis"
                  :label="request.campaignAdminAccepted ? 'Accepted' : 'Pending'"
                />
              </div>

              <div class="list__item-col" data-col="0">
                <Dropdown
                  :position="['right']"
                  :items="[
                    {
                      index: requestIndex,
                      id: `${request._id}`,
                      label: 'Accept',
                    },
                    {
                      index: requestIndex,
                      id: `${request._id}`,
                      label: 'Decline',
                    },
                  ]"
                  :is-show-more="true"
                  @change="decideRequestStatus"
                />
              </div>
            </div>
          </li>
        </List>
      </div>
    </div>
    <div>
      <div v-if="checkIfAdminOrg" id="join-requests" class="flex items-center mb-8 w-full">
        <Title title="Clips" size="medium" />
      </div>

      <StreamerClips :campaign="campaign._id" :handles="campaign.handles" />
    </div>

    <Modal v-if="organizationModal.show" @closeModal="switchOrganizationModal">
      <Tile class="overflow-y-auto p-4 md:p-8" style="max-height: 60vh">
        <Title title="Set organization" size="medium" class="mb-4 text-center" />

        <div class="flex flex-col">
          <Button
            kind="secondary"
            label="Remove organization"
            @clicked="setContentCreatorOrg(organizationModal.contentCreatorId, false)"
          >
          </Button>

          <Button
            v-for="organization of organizationModal.organizations"
            :key="organization.orgId"
            kind="secondary"
            :label="organization.title || organization.orgId"
            @clicked="setContentCreatorOrg(organizationModal.contentCreatorId, organization.orgId)"
          >
          </Button>
        </div>
      </Tile>
    </Modal>
  </main>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { KPI, Avatar, AreaChart, Title, Tag, Button, PieChart, Tile } from "cavea-design-system";
import log from "@/lib/log";
import jsonToCsv from "@/lib/helpers/jsonToCsv";
import calculateCtr from "@/lib/helpers/calculateCtr";
import StreamerClips from "@/components/streamer/StreamerClips";

export default {
  name: "OwnedCampaign",

  metaInfo() {
    return {
      title: this.campaign?.title ?? "Non Owned Campaign",
    };
  },

  components: {
    Title,
    Button,
    List: () => import("@/modules/List"),
    Dropdown: () => import("@/components/Dropdown"),
    Tag,
    Avatar,
    PieChart,
    KPI,
    StreamerClips,
    AreaChart,
    Modal: () => import("@/components/Modal"),
    Tile,
  },

  data() {
    return {
      organizationModal: {
        show: false,
        organizations: [],
        contentCreatorId: null,
      },

      showInactiveStreamers: true,
      liveChannels: null,
      sort: {
        by: "views",
        dir: false, // true = ascending; false = descending
      },

      uniqueClicks: 0,
      showAllCategories: false,

      campaignGraphDataClicks: null,
      campaignGraphDataViews: null,
      joinRequests: null,

      orgTitles: null,

      overlayStatuses: new Map(),
    };
  },

  computed: {
    ...mapGetters(["getCampaigns", "getBrands", "getOrganization"]),

    checkIfAdminOrg() {
      return this.getOrganization?._id === "6104316858d6180c099272b9";
    },

    /**
     * @summary gets campaign from route params
     * @returns {object} - campaign
     */
    campaign() {
      if (this.getCampaigns != null) {
        return this.getCampaigns?.find((campaign) => campaign?._id === this.$route.params.id);
      }

      return null;
    },

    computedCampaign() {
      // TODO: Refactor so this shit makes more sense

      if (!this.campaign) {
        return null;
      }

      // BUG: For some reason the state of campaigns were being mutated, when using: let campaign = this.campaign.
      // This hacky solves that by detaching the obj
      const jsonCampaign = JSON.stringify(this.campaign);
      const campaign = JSON.parse(jsonCampaign);

      if (this.sort.by === "handle") {
        campaign.handles = this.sort.dir
          ? campaign.handles.sort((a, b) => (a?.handle > b?.handle ? 1 : -1))
          : campaign.handles.sort((a, b) => (a?.handle > b?.handle ? -1 : -1));
      } else if (this.sort.by === "views") {
        const sum = (stats) => {
          let total = 0;

          if (stats?.length) {
            for (const stat of stats) {
              if (stat?.views) {
                total += stat?.views || 0;
              }
            }
          }

          return total;
        };

        campaign.handles = this.sort.dir
          ? campaign.handles.sort((a, b) => (sum(a?.statsId?.stats) > sum(b?.statsId?.stats) ? 1 : -1))
          : campaign.handles.sort((a, b) => (sum(a?.statsId?.stats || 0) > sum(b.statsId.stats) ? -1 : 1));
      } else if (this.sort.by === "clicks") {
        campaign.handles = this.sort.dir
          ? campaign.handles.sort((a, b) => (a?.redirectorId?.stats?.length > b?.redirectorId?.stats?.length ? 1 : -1))
          : campaign.handles.sort((a, b) => (a?.redirectorId?.stats?.length > b?.redirectorId?.stats?.length ? -1 : 1));
      } else if (this.sort.by === "ctr") {
        const ctr = (item) => {
          const viewsSum = (stats) => {
            let total = 0;

            if (stats?.length) {
              for (const stat of stats) {
                if (stat?.views) {
                  total += stat?.views || 0;
                }
              }
            }

            return total;
          };

          const clicks = item?.redirectorId?.stats?.length || 0;

          let views = 0;
          if (item?.statsId?.stats && viewsSum(item?.statsId?.stats)) {
            views = viewsSum(item?.statsId?.stats) || 0;
          }

          if (clicks && views) {
            return clicks / views;
          }

          return 0;
        };

        campaign.handles = this.sort.dir
          ? campaign?.handles?.sort((a, b) => (ctr(a) > ctr(b) ? 1 : -1))
          : campaign?.handles?.sort((a, b) => (ctr(a) > ctr(b) ? -1 : 1));
      } else if (this.sort.by === "timesDisplayed") {
        campaign.handles = this.sort.dir
          ? campaign?.handles?.sort((a, b) => (a?.statsId?.stats?.length > b?.statsId?.stats?.length ? 1 : -1))
          : campaign?.handles?.sort((a, b) => (a?.statsId?.stats?.length > b?.stats?.statsId?.length ? -1 : 1));
      }

      if (!this.showInactiveStreamers) {
        campaign.handles = campaign?.handles?.filter((h) => h?.statsId?.stats?.length > 0);
      }

      return campaign;
    },

    /**
     * @summary get times ad has been displayed
     * @returns {number}
     */
    getTotalTimesDisplayed() {
      let displayCount = 0;

      if (this.campaign?.handles?.length) {
        for (let i = 0; i < this.campaign?.handles?.length; ++i) {
          if (this.campaign?.handles[i]?.statsId?.stats?.length)
            displayCount += this.campaign?.handles[i]?.statsId?.stats?.length || 0;
        }
      }

      return displayCount;
    },

    /**
     * @summary get amount of link clicks
     * @returns {number}
     */
    getTotalLinkClick() {
      let linkClicks = 0;

      for (let i = 0; i < this.campaign?.handles?.length; ++i) {
        if (this.campaign?.handles[i]?.redirectorId?.stats) {
          linkClicks += this.campaign?.handles[i]?.redirectorId?.stats?.length || 0;
        }
      }

      return linkClicks;
    },

    /**
     * @summary get link ctr
     * @returns {number} ctr
     */
    getTotalLinkCtr() {
      let ctr = 0;

      if (this.getTotalViews && this.getTotalLinkClick) {
        ctr = ((this.getTotalLinkClick / this.getTotalViews) * 100).toFixed(2);
      }
      return ctr;
    },

    getUniqueCtr() {
      return parseFloat((((this?.uniqueClicks || 0) / (this.getTotalViews || 0)) * 100)?.toFixed(2), 10) || 0;
    },

    /**
     * @summary get average ad views
     * @returns {number} avgViews
     */
    getTotalAverageViews() {
      let avg = 0;

      if (this.getTotalViews && this.getTotalTimesDisplayed) {
        avg = (this.getTotalViews / this.getTotalTimesDisplayed).toFixed(2);
      }

      return avg;
    },

    /**
     * @summary get total ad views
     * @returns {number} total views
     */
    getTotalViews() {
      let totalViews = 0;

      if (this.campaign?.handles?.length) {
        for (let i = 0; i < this.campaign?.handles?.length; ++i) {
          if (this.campaign?.handles[i]?.statsId?.stats?.length) {
            for (let j = 0; j < this.campaign?.handles[i]?.statsId?.stats?.length; ++j) {
              if (this.campaign?.handles[i]?.statsId?.stats[j]?.views) {
                totalViews += this.campaign.handles[i].statsId.stats[j].views;
              }
            }
          }
        }
      }

      return totalViews;
    },

    /**
     * @summary generate {game: string, views: number}[] of categories and sort it highest - lowest
     */
    gameCategories() {
      let categories = [];

      if (this.campaign?.handles?.length) {
        // Find all handles with stats
        const handles = this.campaign?.handles?.filter((elm) => elm?.statsId?.stats?.length > 0);

        // Return all stats and flatten the array
        const stats = handles?.map((elm) => elm?.statsId?.stats)?.flat();

        const otherObj = {
          game: "Other",
          val: 0,
        };

        for (let i = 0; i < stats?.length; ++i) {
          // If the game category isn't set, then set it as "Other"
          if (!stats[i]?.extra[0]?.game || stats[i]?.extra[0]?.game === "") {
            otherObj.val += stats[i]?.views || 0;

            continue;
          }

          // Check if category already exist
          const categoryIndex = categories?.findIndex(
            (elm) => elm?.type?.toLowerCase() === stats[i]?.extra[0]?.game?.toLowerCase()
          );

          if (categoryIndex !== -1) {
            categories[categoryIndex].val += stats[i]?.views || 0;
          } else {
            categories.push({
              type: stats[i]?.extra[0]?.game,
              val: stats[i]?.views,
            });
          }
        }

        // Sort categories
        categories = categories?.sort((a, b) => (a?.val >= b?.val ? -1 : 1));

        if (otherObj?.val > 0) {
          categories.push(otherObj);
        }
      }

      return categories;
    },

    viewsByDay() {
      if (this.campaignGraphDataViews?.length) {
        const labels = [];
        const viewsByDay = [];
        const viewsTotal = [];

        let total = 0;
        for (let i = 0; i < this.campaignGraphDataViews?.length; ++i) {
          if (this.campaignGraphDataViews[i]?._id?.date) {
            total += this.campaignGraphDataViews[i]?.views || 0;

            labels.push(this.campaignGraphDataViews[i]?._id?.date);

            viewsByDay.push(this.campaignGraphDataViews[i]?.views || 0);

            viewsTotal.push(total);
          }
        }

        return {
          series: [
            {
              name: "Views by day",
              data: viewsByDay,
            },
            {
              name: "Total views",
              data: viewsTotal,
            },
          ],
          labels,
        };
      }

      return null;
    },

    clicksByDay() {
      if (this.campaignGraphDataClicks?.length) {
        const labels = [];
        const clicksByDay = [];
        const clicksTotal = [];

        let total = 0;
        for (let i = 0; i < this.campaignGraphDataClicks?.length; ++i) {
          if (this.campaignGraphDataClicks[i]?._id?.date) {
            total += this.campaignGraphDataClicks[i]?.clicks || 0;

            labels.push(this.campaignGraphDataClicks[i]?._id?.date);

            clicksByDay.push(this.campaignGraphDataClicks[i]?.clicks || 0);

            clicksTotal.push(total);
          }
        }

        return {
          series: [
            {
              name: "Clicks by day",
              data: clicksByDay,
            },
            {
              name: "Total clicks",
              data: clicksTotal,
            },
          ],
          labels,
        };
      }
      return null;
    },

    ctrByDay() {
      if (this.campaignGraphDataViews?.length && this.campaignGraphDataClicks?.length) {
        const dailyCtr = [];
        const totalCtr = [];
        const labels = [];

        let clickIndex = 0;
        let viewIndex = 0;

        let totalViews = 0;
        let totalClicks = 0;

        while (viewIndex < this.campaignGraphDataViews?.length) {
          if (
            this.campaignGraphDataViews[viewIndex]?._id?.date === this.campaignGraphDataClicks[clickIndex]?._id?.date
          ) {
            totalViews += this.campaignGraphDataViews[viewIndex]?.views || 0;
            totalClicks += this.campaignGraphDataClicks[clickIndex]?.clicks || 0;

            labels.push(this.campaignGraphDataViews[viewIndex]?._id?.date);

            dailyCtr.push(
              calculateCtr(
                this.campaignGraphDataViews[viewIndex]?.views || 0,
                this.campaignGraphDataClicks[clickIndex]?.clicks || 0
              )
            );

            totalCtr.push(calculateCtr(totalViews, totalClicks));

            clickIndex += 1;
            viewIndex += 1;
          } else if (
            clickIndex <= this.campaignGraphDataClicks?.length &&
            new Date(this.campaignGraphDataViews[viewIndex]?._id?.date) >
              new Date(this.campaignGraphDataClicks[clickIndex]?._id?.date)
          ) {
            totalClicks += this.campaignGraphDataClicks[clickIndex]?.clicks || 0;

            clickIndex += 1;
          } else {
            totalViews += this.campaignGraphDataViews[viewIndex]?.views || 0;

            labels.push(this.campaignGraphDataViews[viewIndex]?._id?.date);
            dailyCtr.push(0);

            totalCtr.push(calculateCtr(totalViews, totalClicks));

            viewIndex += 1;
          }
        }

        return {
          series: [
            {
              name: "CTR by day",
              data: dailyCtr,
            },
            {
              name: "Total CTR",
              data: totalCtr,
            },
          ],
          labels,
        };
      }

      return null;
    },

    getBrandImg() {
      const brandIndex = this.getBrands?.findIndex((b) => b?._id === this.campaign?.brand?.id);

      return brandIndex !== -1 ? this.getBrands[brandIndex]?.logo : null;
    },
  },

  watch: {
    campaign: {
      handler() {
        if (!this.liveChannels) {
          this.getLiveChannels();
        }

        if (!this.orgTitles) {
          this.fetchOrgTitles();
        }

        if (!this.overlayStatuses) {
          this.fetchOverlayStatus();
        }
      },
    },
  },

  created() {
    if (this.getUserInfo?.type === "streamer") {
      this.$router.push("/");
    }

    this.fetchCampaigns();
    this.fetchCampaignGraphDataViews();
    this.fetchCampaignGraphDataClicks();
    this.fetchUniqueClicks();
    this.fetchOrgTitles();

    if (this.checkIfAdminOrg) {
      this.fetchRequestsToJoin();
    }

    this.fetchOverlayStatus();
  },

  methods: {
    ...mapMutations(["setExistingCampaign", "setCampaign"]),

    ...mapActions(["fetchCampaigns"]),

    /**
     * @summary edit campaign by id
     * @param {string} id - AdCampaign ObjectId
     */
    editCampaign() {
      this.setExistingCampaign(this.$route.params.id);

      this.$router.push("/create/campaign/settings");
    },

    /**
     * @summary remove handle
     * @param {string} handle
     */
    async removeHandle(contentCreatorId) {
      log(`removeHandle ${contentCreatorId}`);

      const contentCreatorIndex = this.campaign?.handles?.findIndex(
        (h) => h?.contentCreatorId?._id === contentCreatorId
      );

      log(`contentCreatorIndex ${contentCreatorIndex}`);

      if (typeof contentCreatorIndex === "number" && contentCreatorIndex !== -1) {
        const confirmation = await this.$swal({
          title: `Are you sure you want to ${
            this.campaign?.handles[contentCreatorIndex]?.active ? "disable" : "enable"
          } ${this.campaign?.handles[contentCreatorIndex]?.handle} on this campaign?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#c13859",
          confirmButtonText: `Yes, ${this.campaign?.handles[contentCreatorIndex]?.active ? "disable" : "enable"} it!`,
        });

        if (confirmation?.isConfirmed) {
          const HOST = process.env.VUE_APP_API_URL;
          const URL = `${HOST}/ad-campaign/switch-handle-active/${this.campaign._id}/${contentCreatorId}`;

          await axios
            .put(URL)
            .then(() => {
              this.$swal({
                icon: "success",
                title: `Handle has been ${
                  this.campaign?.handles[contentCreatorIndex]?.active ? "disabled" : "enabled"
                }`,
                showConfirmButton: false,
                timer: 1000,
              });

              this.fetchCampaigns();
            })
            .catch((err) => {
              console.error("removeHandle", err);

              this.$swal({
                icon: "error",
                title: "Oops, something went wrong!",
                text: "Please try again later",
              });
            });
        }
      }
    },

    /**
     * @summary get total ad views by streamer handle
     * @param {string} handle - streamer handle
     * @returns {number} totalViews
     */
    getStreamerViews(handle) {
      let totalViews = 0;

      const streamer = this.campaign?.handles?.find((item) => item?.handle === handle);

      if (streamer?.statsId?.stats) {
        for (const stat of streamer?.statsId?.stats) {
          totalViews += parseInt(stat?.views, 10) || 0;
        }
      }

      return totalViews;
    },

    /**
     * @summary streamer chat bot link clicks
     * @param {string} handle - streamer handle
     */
    getStreamerClicks(handle) {
      let linkClicks = 0;

      const streamer = this.campaign?.handles?.find((item) => item?.handle === handle);

      linkClicks = streamer?.redirectorId?.stats?.length || 0;

      return linkClicks;
    },

    /**
     * @summary streamer campaign ctr
     * @param {string} handle - streamer handle
     */
    getStreamerCtr(handle) {
      let ctr = 0;

      const views = this.getStreamerViews(handle) || 0;
      const clicks = this.getStreamerClicks(handle) || 0;

      if (views && clicks) {
        ctr = ((clicks / views) * 100).toFixed(2);
      }

      return ctr;
    },

    /**
     * @summary get streamer display times
     * @param {string} handle
     */
    getStreamerDisplayTimes(handle) {
      let displayTimes = 0;

      const streamer = this.campaign?.handles?.find((item) => item?.handle === handle);

      if (streamer?.statsId?.stats?.length) {
        displayTimes = streamer?.statsId?.stats?.length || 0;
      }

      return displayTimes;
    },

    /**
     * @summary detect which button is clicked in handle dropdown
     * @param {event} e
     */
    dropdownClick(e) {
      log({ fn: "dropdownClick", e });
      // FIXME: There must be a better way to detect which item is clicked.
      // Switch statement?

      if (e?.item?.label?.toLowerCase() === "disable handle" || e?.item?.label?.toLowerCase() === "enable handle") {
        this.removeHandle(e.item.id);
      } else if (e?.item?.label?.toLowerCase() === "copy stream code") {
        const tempElement = document.createElement("input");
        const overlayLink = `https://overlay.netwrk.gg/?id=${e.item.id}`;
        tempElement.value = overlayLink;
        document.body.appendChild(tempElement);

        tempElement.select();
        document.execCommand("copy");
        document.body.removeChild(tempElement);
      } else if (e?.item?.label?.toLowerCase() === "set organization") {
        this.switchOrganizationModal(e?.item?.id);
      } else if (e?.item?.label?.toLowerCase() === "set cpm" && e?.item?.id) {
        this.setCPM(e?.item?.id);
      } else if (e?.item?.label?.toLowerCase() === "copy redirect url" && e?.item?.id) {
        const contentCreatorIndex = this.computedCampaign?.handles?.findIndex(
          (h) => h?.contentCreatorId?._id?.toString() === e?.item?.id?.toString()
        );

        if (contentCreatorIndex !== -1 && this.computedCampaign?.handles[contentCreatorIndex]?.shortLink?.length) {
          const tempElement = document.createElement("input");
          tempElement.value = this.computedCampaign?.handles[contentCreatorIndex].shortLink;
          document.body.appendChild(tempElement);

          tempElement.select();
          document.execCommand("copy");
          document.body.removeChild(tempElement);
        }
      }
    },

    async setCPM(contentCreatorId) {
      const campaignId = this.campaign._id;

      const contentCreatorIndex = this.campaign?.handles?.length
        ? this.campaign?.handles?.findIndex(
            (c) => c?.contentCreatorId?._id?.toString() === contentCreatorId?.toString()
          )
        : -1;

      let inputValue = 0;

      if (
        this.campaign?.handles[contentCreatorIndex]?.priceRate &&
        typeof this.campaign?.handles[contentCreatorIndex]?.priceRate === "number"
      ) {
        inputValue = this.campaign?.handles[contentCreatorIndex]?.priceRate;
      } else if (this.campaign?.info?.priceRate) {
        inputValue = this.campaign?.info?.priceRate;
      }

      const inputStep = 0.01;

      const modalInput = await this.$swal.fire({
        title: "Set CPM (in €)",
        html: `
    <input
      type="number"
      value="${inputValue}"
      step="${inputStep}"
      class="swal2-input"
      style="max-width:100%;margin:0;"
      id="range-value">`,
        input: "range",
        inputValue,
        inputAttributes: {
          min: 0,
          max: 100,
          step: inputStep,
        },
        didOpen: () => {
          const inputRange = this.$swal.getInput();
          const inputNumber = this.$swal.getHtmlContainer().querySelector("#range-value");

          // remove default output
          inputRange.nextElementSibling.style.display = "none";
          inputRange.style.width = "100%";

          // sync input[type=number] with input[type=range]
          inputRange.addEventListener("input", () => {
            inputNumber.value = inputRange.value;
          });

          // sync input[type=range] with input[type=number]
          inputNumber.addEventListener("change", () => {
            inputRange.value = inputNumber.value;
          });
        },
      });

      if (modalInput?.isConfirmed && campaignId?.length && contentCreatorId?.length) {
        await axios
          .put(`${process.env.VUE_APP_API_URL}/ad-campaign/streamer/set-cpm`, {
            campaignId,
            contentCreatorId,
            priceRate: modalInput?.value,
          })
          .then((res) => {
            log("Updated pricerate succesfully", res);

            this.$swal({
              icon: "success",
              title: `Pricerate has been updated.`,
              showConfirmButton: false,
              timer: 1000,
            });
          })
          .catch((error) => {
            console.error("Error setting pricerate", error);

            this.$swal({
              icon: "error",
              title: "Something went wrong, please try again.",
              timer: 800,
              showConfirmButton: false,
            });
          });

        this.fetchCampaigns();
      }
    },

    handleSort(sortBy) {
      if (this.sort?.by === sortBy) {
        this.sort.dir = !this.sort.dir;
      } else {
        this.sort.dir = sortBy === "handle";
      }

      this.sort.by = sortBy;
    },

    async getLiveChannels() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/active-overlay-streamers`;

      const handles = this.campaign?.handles?.map((h) => h?.handle);

      this.liveChannels = await axios
        .post(URL, { handles })
        .then((res) => res?.data?.handles || [])
        .catch((err) => {
          console.error("error getting live channels", err);
          return [];
        });
    },

    handleStreamStatus(handle) {
      return this.liveChannels?.findIndex((h) => h?.toLowerCase() === handle?.toLowerCase()) !== -1;
    },

    async fetchCampaignGraphDataClicks() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/ad-campaign-charts/${this.$route.params.id}/clicks`;

      this.campaignGraphDataClicks = await axios
        .get(URL)
        .then((res) => res?.data?.clickStats || [])
        .catch((err) => {
          console.error("fetchCampaignGraphData", err?.response?.data);
          return [];
        });
    },

    async fetchCampaignGraphDataViews() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/ad-campaign-charts/${this.$route.params.id}/views`;

      this.campaignGraphDataViews = await axios
        .get(URL)
        .then((res) => res?.data?.viewsStats)
        .catch((err) => {
          console.error("fetchCampaignGraphData", err?.response?.data);
          return [];
        });
    },

    async fetchRequestsToJoin() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/ad-campaigns/join-requests/${this.$route.params.id}`;

      this.joinRequests = await axios
        .get(URL)
        .then((res) => res?.data?.joinRequests)
        .catch((err) => {
          console.error("fetchRequestsToJoin", err);
          return [];
        });
    },

    async decideRequestStatus(e) {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/ad-campaigns/decide-streamer-request`;

      const requestBody = {
        inviteId: e.item.id,
      };

      // Check if request index exists
      const inviteIndex = this.joinRequests?.findIndex((i) => i?._id === e?.item?.id);

      if (typeof inviteIndex === "number" && inviteIndex !== -1) {
        // Confirm that user wants to accept/decline
        const confirmation = await this.$swal({
          title: `Are you sure you want to ${e.item.label.toLowerCase()} ${
            this.joinRequests[inviteIndex]?.contentCreatorId?.username
          } on this campaign?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#c13859",
          confirmButtonText: `Yes!`,
        });

        if (confirmation?.isConfirmed) {
          if (e?.item?.label?.toLowerCase() === "accept") {
            // Accept request
            requestBody.accepted = true;

            await axios
              .put(URL, requestBody)
              .then((res) => res.data)
              .catch((err) => {
                console.error("decideRequestStatus accept", err);
                return err;
              });
          } else if (e?.item?.label?.toLowerCase() === "decline") {
            // Decline request
            requestBody.accepted = false;

            await axios
              .put(URL, requestBody)
              .then((res) => res?.data)
              .catch((err) => {
                console.error("decideRequestStatus decline", err);
                return err;
              });
          }
        }

        this.fetchRequestsToJoin();
        this.fetchCampaigns();
      } else {
        // TODO: error handling
      }

      log(URL);
    },

    profileImageFallback(event) {
      event.target.src = "https://storage.googleapis.com/cavea-avatar-service/twitch/frymealiiiver/avatar.png";
    },

    async fetchUniqueClicks() {
      const HOST = process.env.VUE_APP_API_URL;

      const URL = `${HOST}/ad-campaigns/unique-clicks/${this.$route.params.id}`;
      this.uniqueClicks = await axios
        .get(URL)
        .then((res) => res?.data?.uniqueIps || 0)
        .catch((err) => {
          console.error("fetchUniqueClicks ERROR", err);
          return 0;
        });
    },

    async fetchOrgTitles() {
      log("fetchOrgTitles");

      if (!this.orgTitles) {
        this.orgTitles = {};
      }

      let orgIds = this.campaign?.handles?.filter((h) => h?.orgId).map((h) => h?.orgId);

      // Remove duplicates
      orgIds = orgIds?.filter((o, i) => orgIds?.indexOf(o) === i);

      if (orgIds?.length > 0) {
        for (const orgId of orgIds) {
          if (this.orgTitles[`${orgId}`] === undefined) {
            const HOST = process.env.VUE_APP_API_URL;
            const URL = `${HOST}/organization/title/${orgId}`;

            axios
              .get(URL)
              .then((res) => {
                if (res?.data?.orgTitle) {
                  this.orgTitles[`${orgId}`] = res?.data?.orgTitle;
                }
              })
              .catch((err) => {
                console.error(`fetchOrgTitles ${orgId} ERROR`, err);
                return null;
              });
          }
        }
      }
    },

    getHandleOrgTitle(orgId) {
      return this.orgTitles[`${orgId}`] || null;
    },

    exportStreamerDataCSV() {
      const reducer = (a, b) => a + (b?.views || 0);

      const data = JSON.parse(JSON.stringify(this.campaign?.handles))?.map((h) => ({
        handle: h?.handle,
        views: h?.statsId?.stats?.reduce(reducer, 0),
        times_displayed: h?.statsId?.stats?.length || 0,
        clicks: h?.redirectorId?.stats?.length || 0,
        ctr: `${((h?.redirectorId?.stats?.length || 0) / h?.statsId?.stats?.reduce(reducer, 0) || 0) * 100}%`,
      }));

      const title = this.campaign?.title?.toLowerCase()?.trim()?.replaceAll(" ", "_") || "campaign";

      jsonToCsv(
        data?.sort((a, b) => (a?.views > b?.views ? -1 : 1)) || data,
        `netwrk_${title}_export_${new Date().toISOString().split("T")[0]}.csv`
      );

      log({ fn: "exportStreamerDataCSV", data });
    },

    async switchOrganizationModal(contentCreatorId = null) {
      this.organizationModal.show = !this.organizationModal.show;

      if (this.organizationModal?.show) {
        const contentCreatorIndex = this.campaign?.handles?.findIndex(
          (c) => c?.contentCreatorId?._id?.toString() === contentCreatorId?.toString()
        );

        const organizations =
          contentCreatorIndex !== -1
            ? this.campaign?.handles[contentCreatorIndex]?.contentCreatorId?.organizations
            : [];

        for (let i = 0; i < organizations?.length; i += 1) {
          organizations[i].title = await axios
            .get(`${process.env.VUE_APP_API_URL}/organization/title/${organizations[i].orgId}`)
            .then((res) => res?.data?.orgTitle || null)
            .catch((error) => {
              console.error("Error getting org title", organizations[i].orgId, error);
              return null;
            });
        }

        this.organizationModal.organizations = organizations || [];
        this.organizationModal.contentCreatorId = contentCreatorId;
      } else {
        this.organizationModal = {
          show: false,
          organizations: [],
          contentCreatorId: null,
        };
      }
    },

    async setContentCreatorOrg(contentCreatorId, organizationId) {
      const campaignId = this.$route?.params?.id || this.campaign?._id || this.computedCampaign?._id;

      this.switchOrganizationModal();
      await axios
        .put(`${process.env.VUE_APP_API_URL}/ad-campaign/content-creator/set-organization`, {
          organizationId,
          contentCreatorId,
          campaignId,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Organization set succesfully",
            timer: 800,
          });
        })
        .catch((error) => {
          console.error("setContentCreatorOrg error", error);
          this.$swal({
            icon: "error",
            title: "Something went wrong",
            timer: 800,
          });
        });

      this.fetchCampaigns()?.then(() => this.fetchOrgTitles());
    },

    async fetchOverlayStatus() {
      if (this.campaign?.handles?.length) {
        for (let i = 0; i < this.campaign?.handles?.length; i += 1) {
          const contentCreatorId = this.campaign?.handles[i]?.contentCreatorId?._id;

          if (contentCreatorId?.length) {
            axios
              .get(`${process.env.VUE_APP_API_URL}/streamers/${contentCreatorId}/overlay/active`)
              .then((res) => {
                this.overlayStatuses.set(contentCreatorId, res?.data?.last_active);
              })
              .catch();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.list--campaigns {
  // Title
  [data-col="1"] {
    width: 20%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  [data-col="2"],
  [data-col="3"],
  [data-col="4"],
  [data-col="5"],
  [data-col="6"] {
    width: calc(22.5% - 1.5rem);
    margin-right: 1.5rem;
    align-items: center;
  }

  // Dropdown
  [data-col="0"] {
    width: 1.25rem;
    margin: 0 1.5rem 0 1.5rem;
  }
}
</style>
